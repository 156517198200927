import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import FlexibleBlock from '../components/FlexibleBlock';
import Seo from '../components/Seo';

const Page = ({ data }) => {
  const page = data.sanityPage;
  return (
    <Layout seo={page.seo}>
      {page.blocks.map((block, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <FlexibleBlock key={index} type={block._type} data={block} />
      ))}
    </Layout>
  );
};

export const Head = ({ data }) => {
  const page = data.sanityPage;
  const { seo } = page;
  return (
    <Seo
      title={seo?.title || page.title}
      description={seo?.description}
      openGraphTitle={seo?.openGraphTitle || seo?.title || page.title}
      openGraphDescription={seo?.openGraphDescription || seo?.description}
      openGraphImage={seo?.openGraphImage?.asset.url}
      noFollowNoIndex={seo?.noFollowNoIndex}
    />
  );
};

export const query = graphql`
  query Page($_id: String) {
    sanityPage(_id: {eq: $_id}) {
      title
      blocks {
        ...FlexibleBlockFragment
      }
      seo {
        ...SeoFragment
      }
    }
  }
`;

export default Page;
